import {FC, memo, useState, useMemo} from 'react';





const Stages = [
  {
    name: 'Mine 1 (Stage 1)',
    skills: [
      {
        name: 'Base Speed: 1000/Hour',
        level: 10,
      },
      {
        name: 'Mineable Supply: 10 Billion',
        level: 1,
      },
      {
        name: 'Estimated Price: 0.0001 USD',
        level: 1,
      },
    ],
  },
  {
    name: 'Mine 2 (Stage 2)',
    skills: [
      {
        name: 'Base Speed: 500/Hour',
        level: 5,
      },
      {
        name: 'Mineable Supply: 20 Billion',
        level: 2,
      },
      {
        name: 'Estimated Price: 0.0005 USD',
        level: 3,
      },
    ],
  },
  {
    name: 'Mine 3 (Stage 3)',
    skills: [
      {
        name: 'Base Speed: 250/Hour',
        level: 3,
      },
      {
        name: 'Mineable Supply: 50 Billion',
        level: 4,
      },
      {
        name: 'Estimated Price: 0.0015 USD',
        level: 5,
      },
    ],
  },
  {
    name: 'Mine 4 (Stage 4)',
    skills: [
      {
        name: 'Base Speed: 125/Hour',
        level: 2,
      },
      {
        name: 'Mineable Supply: 80 Billion',
        level: 7,
      },
      {
        name: 'Estimated Price: 0.003 USD',
        level: 6,
      },
    ],
  },
  {
    name: 'Mine 5 (Stage 5)',
    skills: [
      {
        name: 'Base Speed: 62.5/Hour',
        level: 1,
      },
      {
        name: 'Mineable Supply: 100 Billion',
        level: 10,
      },
      {
        name: 'Estimated Price: 0.01 USD',
        level: 10,
      },
    ],
  },
];

  const GuideLine = [
    {
      date: '$5 Plus 5000 $TORA',
      location: 'Invitation Rewards',
      title: 'How to Invite Friends?',
      content: <p>You get $5 Instantly as soon as someone registers using your wallet address as referral code</p>,
    },
    // {
    //   date: '0.01 BNB (~$5)',
    //   location: '5000 $TORA Token',
    //   title: 'How to Claim Referral Rewards?',
    //   content: <p>You don't need to claim your rewards because it will be transfered to your wallet as soon as someone starts mining using your wallet address as referral code </p>,
    // },
  ];
 

  const Details =  [
    
    {
      date: 'Mining Squad',
      location: 'Referral Reward',
      title: 'How Mining Works?',
      content: (
        <div className="Markdown_markdownContainer__Tz3HQ ">
  <p>Torabium SmartContract is a decentralized application (dApp) built on Binance Smart Chain. When you register with the Torabium SmartContract, it will associate your blockchain wallet address with a user profile.</p><br/>
  <p>As a newly registered miner, the SmartContract will automatically assign you a base mining speed of 2211 $TORA per Day. This means that by simply being a registered miner, you will start earning $TORA at a rate of 2211 per Day.</p><br/>
  <p>However, you can increase your mining speed beyond the initial 2211 $TORA/Day by <strong>Unlocking Mining Characters and Upgrading them.</strong>The more characters you unlock and upgrade, the higher your total mining output and $TORA earnings.</p><br/>
  </div>
      ),
    },
    {
      date: 'Add $TORA to Your Wallet',
      location: 'Claim Your Tokens',
      title: 'How to Claim $TORA?',
      content: (
  
        <div>
          <p><strong>Claim your mined tokens</strong></p><br/>
          <p>The Torabium SmartContract has made this process super easy and fast.</p>
          <p>Whenever you want, you can have your tokens in your wallet! All you need to do is click the "Claim" button on <a href='/Mining'>Mining Page</a>.
          </p><br/>
          <p><strong>Pay for the transaction</strong></p><br/>
          <p>When you click Claim, you will need to pay a small transaction fee to the network.
          </p>
          <p>This allows the SmartContract to instantly send your mined $TORA tokens to your connected wallet.</p><br/>
          <p><strong className='text-lime200'>Add the $TORA token to your wallet</strong></p><br/>
          <p>After claiming your tokens, make sure you have added the $TORA token to your wallet using Torabium contract address.
            
  
          </p>
          <p>This will allow you to see and manage your earned $TORA tokens directly from your wallet.
            <br/>
            <br/>

             Contract Address: <br/> <a className='text-lime-400'>0xF1f47A09ce6Ee22B46CF0b58de65E3fdF9a18c42</a> 
             <br/>
            </p><br/>
            
        </div>
      ),
    },
  ];

  const ProgressBar = ({ progress }) => {
    return (
        <div className="w-full bg-gray-200 rounded-full h-3 dark:bg-gray-700">
            <div
                className="bg-lime-500 h-3 rounded-full"
                style={{ width: `${progress}%` }}
            ></div>
        </div>
    );
};
 
  
const Guide = () => {
  const [message, setMessage] = useState('');
  const {name, level, max = 10} = Stages;
    const percentage = useMemo(() => Math.round((level / max) * 100), [level, max]);
    
  const copyToClipboard = () => {
    navigator.clipboard.writeText("0xF1f47A09ce6Ee22B46CF0b58de65E3fdF9a18c42");
    setMessage('Contract Address copied to clipboard!');
    setTimeout(() => setMessage(''), 3000);
};
  

  return (
   <div className=' p-10 md:p-20 bg-neutral-800 '>


      {GuideLine.map((Props) => (
        <div className="flex flex-col pb-8 text-center text-gray-200 last:pb-0 md:text-left">
        <div className="flex flex-col pb-4">
          <h2 className="text-xl font-bold text-lime-300">{Props.title}</h2>
          <div className="flex items-center justify-center gap-x-2 md:justify-start">
            <span className="flex-1 text-sm font-medium italic sm:flex-none text-lime-200">{Props.location}</span>
            <span>•</span>
            <span className="flex-1 text-sm sm:flex-none text-lime-200">{Props.date}</span>
          </div>
        </div>
        {Props.content}
      </div>

))} 
    {Details.map((Props) => (
        <div className="flex flex-col pb- text-center text-gray-200 last:pb-0 md:text-left">
        <div className="flex flex-col pb-">
          <h2 className="text-xl font-bold text-lime-300">{Props.title}</h2>
          <div className="flex items-center justify-center gap-x-2 md:justify-start">
            <span className="flex-1 text-sm font-medium italic sm:flex-none text-lime-200">{Props.location}</span>
            <span>•</span>
            <span className="flex-1 text-sm sm:flex-none text-lime-200">{Props.date}</span>
          </div>
        </div>
        {Props.content}

        

      </div>

))}
         <div className='flex grid grid-cols-1 w-screen  mb-8'>

         <button onClick={copyToClipboard} className=' bg-violet-400 text stone-200 rounded-lg p-3 mr-20  text-stone-200'>Copy Contract Address</button>
         {message && <div className="text-center text-green-600 mt-2">{message}</div>}
         </div>
         


           <h1 className="text-lime-300 text-center font-bold text-2xl m-4">Mining Stages & Supply Distribution</h1>


           <h1 className="text-gray-200 text-center font-bold text-xl m-4">Stage 1</h1>
        <div className="py-1">
            <h2 className=" font-bold text-gray-200">Mineable Supply: 10B</h2>
            <ProgressBar progress={5} />
        </div>
        <div className="py-1">
            <h2 className=" font-bold text-gray-200">Mining Speed: 2211 TORA/DAY</h2>
            <ProgressBar progress={100} />
        </div>
        <div className="py-1">
            <h2 className=" font-bold text-gray-200">Least Estimated Price: $0.0005</h2>
            <ProgressBar progress={5} />
        </div>        

        <h1 className="text-gray-200 text-center font-bold text-xl m-4">Stage 2</h1>
        <div className="py-1">
            <h2 className=" font-bold text-gray-200">Mineable Supply: 20B</h2>
            <ProgressBar progress={10} />
        </div>
        <div className="py-1">
            <h2 className=" font-bold text-gray-200">Mining Speed: 1105 TORA/DAY</h2>
            <ProgressBar progress={50} />
        </div>
        <div className="py-1">
            <h2 className=" font-bold text-gray-200">Least Estimated Price: $0.001</h2>
            <ProgressBar progress={10} />
        </div>        
        <h1 className="text-gray-200 text-center font-bold text-xl m-4">Stage 3</h1>
        <div className="py-1">
            <h2 className=" font-bold text-gray-200">Mineable Supply: 50B</h2>
            <ProgressBar progress={25} />
        </div>
        <div className="py-1">
            <h2 className=" font-bold text-gray-200">Mining Speed: 553 TORA/DAY</h2>
            <ProgressBar progress={25} />
        </div>
        <div className="py-1">
            <h2 className=" font-bold text-gray-200">Least Estimated Price: $0.005</h2>
            <ProgressBar progress={20} />
        </div>        
        <h1 className="text-gray-200 text-center font-bold text-xl m-4">Stage 4</h1>
        <div className="py-1">
            <h2 className=" font-bold text-gray-200">Mineable Supply: 100B</h2>
            <ProgressBar progress={60} />
        </div>
        <div className="py-1">
            <h2 className=" font-bold text-gray-200">Mining Speed: 276 TORA/DAY</h2>
            <ProgressBar progress={12.5} />
        </div>
        <div className="py-1">
            <h2 className=" font-bold text-gray-200">Least Estimated Price: $0.01</h2>
            <ProgressBar progress={50} />
        </div>       
        <h1 className="text-gray-200 text-center font-bold text-xl m-4">Stage 5</h1>
        <div className="py-1">
            <h2 className=" font-bold text-gray-200">Mineable Supply: 180B</h2>
            <ProgressBar progress={100} />
        </div>
        <div className="py-1">
            <h2 className=" font-bold text-gray-200">Mining Speed: 138 TORA/DAY</h2>
            <ProgressBar progress={6} />
        </div>
        <div className="py-1">
            <h2 className=" font-bold text-gray-200">Least Estimated Price: $0.1</h2>
            <ProgressBar progress={100} />
        </div>        
</div>


  );



}


export default Guide;
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { FaHome, FaUserCircle, FaServicestack, FaUserAstronaut , FaUserPlus, FaFileContract } from 'react-icons/fa';
import './index.css';
import './Mine.css';
import './HomePage.css'; 
import './Profile.css';
import Guide from './components/Guide';
import {GiWhiteBook  } from "react-icons/gi";
import QRCodeCanvas, { QRCodeSVG } from 'qrcode.react';
import backgroundHome from'./images/homebg.webm'; //
import { useParams } from 'react-router-dom';
import backgroundVideo from'./images/homebg.webm'; //
import RoadMap from './components/RoadMap';
import { BsTwitterX } from "react-icons/bs";
import { FaTelegram, FaFacebook,FaYoutube } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";
import {UsersIcon,ChartPieIcon,BoltIcon,UserGroupIcon,CurrencyDollarIcon,RocketLaunchIcon,FireIcon, ClockIcon,} from '@heroicons/react/24/outline';
import profilepic from './logo.svg';
import Slider from 'react-slick';
import Hero1 from './images/Heroes/rover.webp'
import Hero2 from './images/Heroes/meteoric.webp'
import Hero3 from './images/Heroes/astro.webp'
import Hero4 from './images/Heroes/quantum.webp'
import Hero5 from './images/Heroes/gravimetric.webp'
import Hero6 from './images/Heroes/aurora.webp'
import Hero7 from './images/Heroes/celestial.webp'
import Hero8 from './images/Heroes/nebula.webp'
import Hero9 from './images/Heroes/ionized-Miner.webp'
import Hero10 from './images/Heroes/Hydra.webp'
import backgroundMine from './videos/backgr.webm';
import lefts from './images/lefts.webp';
import { FaInfoCircle } from "react-icons/fa";
import { EthereumProvider } from '@walletconnect/ethereum-provider'
import { createAppKit } from '@reown/appkit'
import { useAppKitAccount } from '@reown/appkit/react'
import { binanceSmartChain } from '@reown/appkit/networks'
import contractABI from './TORAABI.json';
import {ethers } from 'ethers';
import { BiCoinStack } from 'react-icons/bi';
// 1. Get projectId from https://cloud.reown.com


















const projectId ="0b45e0d8331ad840b8405f03e578c52b";
const metadata = {
  name: 'Torabium Ecosystem',
  description: 'Mine TORA DeFi and earn an instant Reward of $5 for each new member you invite.',
  url: 'https://torabium.com', // origin must match your domain & subdomain
  icons: ['https://torabium.com/static/media/logo.bec80326e12b42d53b336a28a7412fd6.svg']

}
// 2. Create the AppKit instance
const modal =  new createAppKit({
  metadata: metadata,
  enableInjected: true,
  networks: [binanceSmartChain],
  projectId,
  features: {
    analytics: true // Optional - defaults to your Cloud configuration
  }
})


const contractAddress = "0xF1f47A09ce6Ee22B46CF0b58de65E3fdF9a18c42"; // Replace with your contract address

const KB = 1024;


const App = () => {
  const [account, setAccount] = useState('');
  const [_Provider, setProvider] = useState(null);
  const [contract, setContract] = useState(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [isMiner, setisMiner] = useState(false);
  const [Address, setAddress] = useState('');
  const [burnt, setburnt]  = useState();
  const [inviteeAddress, setInviteeAddress] = useState('');
  const [Supply, setSupply] = useState();
  const [CStage, setCStage] = useState();
  const [Miners, setMiners] = useState();
  const [MinedBal, setMinedBal] = useState();
  const [Bal, setBal] = useState();
  const [Rcount, setRcount] = useState();
  const [SpeedD, setSpeedD] = useState();
  const [SpeedH, setSpeedH] = useState();
  const [Sec, setSec] = useState();
  const [H1LVL, setH1LVL] = useState(0);
  const [H2LVL, setH2LVL] = useState(0);
  const [H3LVL, setH3LVL] = useState(0);
  const [H4LVL, setH4LVL] = useState(0);
  const [H5LVL, setH5LVL] = useState(0);
  const [H6LVL, setH6LVL] = useState(0);
  const [H7LVL, setH7LVL] = useState(0);
  const [H8LVL, setH8LVL] = useState(0);
  const [H9LVL, setH9LVL] = useState(0);
  const [H10LVL, setH10LVL] = useState(0);


  const { address, status, isConnected } = useAppKitAccount();

const connectWallet = async () => {

  try {
          await modal.open();
    const provider = await EthereumProvider.init({
      projectId: '0b45e0d8331ad840b8405f03e578c52b',
      metadata: {
        name: 'Torabium Ecosystem',
        description: 'Mine TORA DeFi and earn an instant Reward of $5 for each new member you invite.',
        url: 'https://torabium.com', // origin must match your domain & subdomain
        icons: ['https://torabium.com/static/media/logo.bec80326e12b42d53b336a28a7412fd6.svg']
      },
      showQrModal: true,
      optionalChains: [56],
    
      /*Optional - Add custom RPCs for each supported chain*/
      rpcMap: {
        56: 'https://bsc-dataseed.binance.org/',
      }
    })

    const Accounts = await provider.request({ method: 'eth_requestAccounts' })
    setAccount(Accounts[0])
    const Provider =  new ethers.providers.Web3Provider(provider);

    const _contract = new ethers.Contract(contractAddress, contractABI, Provider.getSigner());
    setContract(_contract);

      if (account && contract) {
        fetchUserData(account);
        fetchToraData(contract);
        modal.open();
      }
      else if (!account && contract) {
        modal.open();
      }
  } catch (error) {
      alert("Please connect Your wallet!", error);
      setMessage("Connection failed.");
  }
};


const refresh = async () => {

  try {
   
    const provider = await EthereumProvider.init({
      projectId: '0b45e0d8331ad840b8405f03e578c52b',
      metadata: {
        name: 'Torabium Ecosystem',
        description: 'Mine TORA DeFi and earn an instant Reward of $5 for each new member you invite.',
        url: 'https://torabium.com', // origin must match your domain & subdomain
        icons: ['https://torabium.com/static/media/logo.bec80326e12b42d53b336a28a7412fd6.svg']
      },
      showQrModal: true,
      optionalChains: [56],
    
      /*Optional - Add custom RPCs for each supported chain*/
      rpcMap: {
        56: 'https://bsc-dataseed.binance.org/',
      }
    })

    const Accounts = await provider.request({ method: 'eth_requestAccounts' })
    setAccount(Accounts[0])
    const Provider =  new ethers.providers.Web3Provider(provider);

    const _contract = new ethers.Contract(contractAddress, contractABI, Provider.getSigner());
    setContract(_contract);

      if (isConnected) {
        fetchUserData(account);
        fetchToraData(contract); 

      }
      else if (!isConnected) {
        
         }
  } catch (error) {
      alert("Please Connect Your Wallet");
      setMessage("Connection failed.", error);
  }
};



useEffect(() => {
  if (contract && account) {
      fetchUserData(account);
      fetchToraData(contract);
  }else {
    refresh();
  }
}, [contract, account]); // Run when contract or account changes

const fetchToraData = async (contract) => {
  try {
      const _Supply = await contract.CirculatingSupply(); 
      setSupply(_Supply/10**18); // Store as string for display
      const stage = await contract.CurrentStage(); 
      setCStage(stage/1);
      const stageMiners = await contract.CurrentStageMiners(); 
      setMiners(stageMiners/1);
      const _burnt = await contract.initialBurn();
      setburnt(_burnt/10**18); //
  } catch (error) {
      console.error("Error fetching Tora data:", error);
  }
};

const fetchUserData = async (address) => {
  if (contract) {
      try {
          const _isMiner = await contract._Miner(address);
          setisMiner(_isMiner);
          const fetchedBalance = await contract.balanceOf(address); 
          setBal(fetchedBalance/10**18);
          const fetchedMinedB = await contract.Mined(address); 
          setMinedBal(fetchedMinedB/10**18);
          const fetchedInvites = await contract.Referral_Count(address); 
          setRcount(fetchedInvites/1);
          const fetchedSpeedH = await contract.Speed_Per_Hour(address); 
          setSpeedH(fetchedSpeedH/1);
          const fetchedSpeedD = await contract.Daily_Mining_Speed(address); 
          setSpeedD(fetchedSpeedD/1);
          const fetchedSec = await contract.GetSec(); 
          setSec(fetchedSec/1);


          // Heroes 
          const h1 = await contract.RoverLevel(address);    
          setH1LVL(h1/1);
          const h2 = await contract.MeteoricLevel(address);
          setH2LVL(h2/1);
          const h3 = await contract.AstroLevel(address);
          setH3LVL(h3/1);
          const h4 = await contract.QuantumLevel(address);
          setH4LVL(h4/1);
          const h5 = await contract.GravimetricLevel(address);
          setH5LVL(h5/1);
          const h6 = await contract.AuroraLevel(address);
          setH6LVL(h6/1);
          const h7 = await contract.CelestialLevel(address);
          setH7LVL(h7/1);
          const h8 = await contract.NebulaLevel(address);
          setH8LVL(h8/1);
          const h9 = await contract.QuantumMinerLevel(address);
          setH9LVL(h9/1);
          const h10 = await contract.HydraLevel(address);
          setH10LVL(h10/1);


      } catch (error) {
          console.error("Error fetching user data from contract:", error);
      }
  }
};


// const getSeconds = () => {
//           if (Sec > 86400000) {
//             return 0;
//           } else {
//             return Sec;
//           }
// }
const _Mine = async () => {
  if (contract) {
      try {
          if (MinedBal < 1) {
              alert("Insufficient Tora tokens  to Claim!.");
              return;
          }


          const tx = await contract.Mine(); // Ensure to use the correct units
          await tx.wait(); // Wait for the transaction to be mined
          alert("Claimed successfully! Check Balance in Your Wallet!", );
      } catch (error) {
          console.error("Could Not Claim! Make Sure You are Miner!", error);
      }
  }
};

const upgradeRover = async () => {
  if (contract) {
      try {
          if (Bal < KB*H1LVL) {
              alert("Insufficient Tora tokens for upgrade.");
              return;
          }
          if (Rcount < 2) {
              alert("You need at least 2 referrals to upgrade.");
              return;
          }

          const tx = await contract.UpgradeRover(); // Ensure to use the correct units
          await tx.wait(); // Wait for the transaction to be mined
          alert("Rover upgraded successfully:", tx);
      } catch (error) {
          console.error("Error upgrading hero:", error);
      }
  }
};

const upgradeMet = async () => {
  if (contract) {
      try {
          if (Bal < 2*KB*H2LVL) {
              alert("Insufficient Tora tokens for upgrade.");
              return;
          }
          if (Rcount < 5) {
              alert("You need at least 5 referrals to upgrade.");
              return;
          }

          const tx = await contract.UpgradeMeteoric(); // Ensure to use the correct units
          await tx.wait(); // Wait for the transaction to be mined
          alert(" Meteoric upgraded successfully:", tx);
      } catch (error) {
          console.error("Error upgrading hero:", error);
      }
  }
};
const upgradeAstro = async () => {
  if (contract) {
      try {
          if (Bal < 3*KB*H3LVL) {
              alert("Insufficient Tora tokens for upgrade.");
              return;
          }
          if (Rcount < 7) {
              alert("You need at least 7 referrals to upgrade.");
              return;
          }

          const tx = await contract.UpgradeAstro(); // Ensure to use the correct units
          await tx.wait(); // Wait for the transaction to be mined
          alert("Astro upgraded successfully:", tx);
      } catch (error) {
          console.error("Error upgrading hero:", error);
      }
  }
};
const upgradeQuantumS = async () => {
  if (contract) {
      try {
          if (Bal < 4*KB*H4LVL) {
              alert("Insufficient Tora tokens for upgrade.");
              return;
          }
          if (Rcount < 10) {
              alert("You need at least 10 referrals to upgrade.");
              return;
          }

          const tx = await contract.UpgradeQuantumS(); // Ensure to use the correct units
          await tx.wait(); // Wait for the transaction to be mined
          alert("Quantum Scanner upgraded successfully:", tx);
      } catch (error) {
          console.error("Error upgrading hero:", error);
      }
  }
};
const upgradeGravi = async () => {
  if (contract) {
      try {
          if (Bal < 5*KB*H5LVL) {
              alert("Insufficient Tora tokens for upgrade.");
              return;
          }
          if (Rcount < 20) {
              alert("You need at least 20 referrals to upgrade.");
              return;
          }

          const tx = await contract.Gravimetric(); // Ensure to use the correct units
          await tx.wait(); // Wait for the transaction to be mined
          alert("Gravimetric upgraded successfully:", tx);
      } catch (error) {
          console.error("Error upgrading hero:", error);
      }
  }
};
const upgradeAurora = async () => {
  if (contract) {
      try {
          if (Bal < 8*KB*H6LVL) {
              alert("Insufficient Tora tokens for upgrade.");
              return;
          }
          if (Rcount < 50) {
              alert("You need at least 50 referrals to upgrade.");
              return;
          }

          const tx = await contract.UpgradeAurora(); // Ensure to use the correct units
          await tx.wait(); // Wait for the transaction to be mined
          console.log("Aurora upgraded successfully:", tx);
      } catch (error) {
          console.error("Error upgrading hero:", error);
      }
  }
};
const upgradeCelestial = async () => {
  if (contract) {
      try {
          if (Bal < 15*KB*H7LVL) {
              alert("Insufficient Tora tokens for upgrade.");
              return;
          }
          if (Rcount < 100) {
              alert("You need at least 100 referrals to upgrade.");
              return;
          }

          const tx = await contract.UpgradeCelestial(); // Ensure to use the correct units
          await tx.wait(); // Wait for the transaction to be mined
          alert("Celestial upgraded successfully:", tx);
      } catch (error) {
          console.error("Error upgrading hero:", error);
      }
  }
};
const upgradeNebula = async () => {
  if (contract) {
      try {
          if (Bal < 20*KB*H8LVL) {
              alert("Insufficient Tora tokens for upgrade.");
              return;
          }
          if (Rcount < 200) {
              alert("You need at least 200 referrals to upgrade.");
              return;
          }

          const tx = await contract.UpgradeNebula(); // Ensure to use the correct units
          await tx.wait(); // Wait for the transaction to be mined
          alert("Nebula upgraded successfully:", tx);
      } catch (error) {
          console.error("Error upgrading hero:", error);
      }
  }
};
const upgradeQuantumM = async () => {
  if (contract) {
      try {
          if (Bal < 30*KB*H9LVL) {
              alert("Insufficient Tora tokens for upgrade.");
              return;
          }
          if (Rcount < 300) {
              alert("You need at least 300 referrals to upgrade.");
              return;
          }

          const tx = await contract.UpgradeQuantumMiner(); // Ensure to use the correct units
          await tx.wait(); // Wait for the transaction to be mined
          alert("Quantum Miner upgraded successfully:", tx);
      } catch (error) {
          console.error("Error upgrading hero:", error);
      }
  }
};
const upgradeHydra = async () => {
  if (contract) {
      try {
          if (Bal < 50*KB*H10LVL) {
              alert("Insufficient Tora tokens for upgrade.");
              return;
          }
          if (Rcount < 600) {
              alert("You need at least 600 referrals to upgrade.");
              return;
          }

          const tx = await contract.UpgradeHydra(); // Ensure to use the correct units
          await tx.wait(); // Wait for the transaction to be mined
          alert("Hydra upgraded successfully:", tx);
      } catch (error) {
          console.error("Error upgrading hero:", error);
      }
  }
};

const HeroUpgrade = {
   1: upgradeRover,
   2: upgradeMet,
   3: upgradeAstro,
   4: upgradeQuantumS,
   5: upgradeGravi,
   6: upgradeAurora,
   7: upgradeCelestial,
   8: upgradeNebula,
   9: upgradeQuantumM,
   10: upgradeHydra,
}
const BecomeAMiner = async () => {

  
  
  if (isMiner) {
    alert("Already A Miner!");
    return;
  } 
  else if (!inviteeAddress) {
    alert("Please paste a Valid invitee address!");
    return;
  } 
    else if (!contract) {
    alert ("Please make Sure your wallet is connected!");
    return;
  }
  else {
    try {
          
      const valueInWei = ethers.utils.parseEther("0.03"); // Convert 0.03 BNB to Wei
      const tx = await contract.RegisterMiner(inviteeAddress, {
          value: valueInWei,
      });
      await tx.wait(); // Wait for the transaction to be mined
      alert ("Congradulations!!! Now You are A Miner!", tx);
  } catch (error) {
      alert ("Insufficient Balance! Make Sure You have 0.03 BNB in Your Wallet!", error);
  }
  }
};

function secondsToDhms(seconds) {
  seconds = Number(seconds)
  var d = Math.floor(seconds / (3600 * 24))
  var h = Math.floor((seconds % (3600 * 24)) / 3600)
  var m = Math.floor((seconds % 3600) / 60)
  // console.log(d, h, m, s)
  var dDisplay = d > 0 ? d + (d == 1 ? " Day, " : " Days, ") : ""
  var hDisplay = h > 0 ? h + (h == 1 ? " Hour, " : " Hours, ") : ""
  var mDisplay = m > 0 ? m + (m == 1 ? " Minute, " : " Minutes ") : ""
  return dDisplay + hDisplay + mDisplay 
}



const Referral = () => {
    // Replace with a random GIF URL or use a GIF API
    const { id } = useParams(); // Get the referral ID from the URL
    const [inviteeAddress, setInviteeAddress] = useState('');

    useEffect(() => {
        if (id) {
            setInviteeAddress(id); // Set the referral ID from the URL
        }
    }, [id]);
  
    return (
      <div className="relative bg-gray-950 w-full h-full overflow-hidden">
      <video
            className="absolute  justify-start  h-screen w-screen overflow"
            src={backgroundVideo}
            autoPlay
            loop
            muted
          />
        <div className="absolute  inset-0 bg-black opacity-50"></div>
        <div className="relative flex flex-col justify-center backdrop-blur-sm bg-zinc-500/30 items-center rounded-2xl text-white text-center p-10 m-10 mt-20">
          <h3 className="text-3xl font-bold mb-4 text-violet-100 transform transition duration-500 hover:scale-105 animate-fadeIn">
            ToraDeFi
          </h3>
          <>
        <p className="prose-sm text-lime-200 text-start sm:prose-base lg:prose-lg">
        <strong className="text-lime-300"> Mine TORA DeFi </strong> and earn an instant 
          <strong className="text-lime-300"> Reward of $5 </strong> 
          for each new member you invite.
        </p>
        <p className="prose-sm text-lime-200 text-start sm:prose-base lg:prose-lg">
        Enjoy the benefits of  <strong className="text-lime-300"> Instant Withdrawals </strong>
        with real-time Data on the blockchain.</p>
        <p> <br />
          <strong className="text-violet-500 "> Join us in building a thriving community where everyone can succeed!</strong>
        </p>
      </>

      <div className="max-w-lg mx-auto p-6 bg-violet-600/30 rounded-lg shadow-md mt-5">
            <p className="text-xl font-bold text-violet-100 text-center">Invitee Address <br/><sm>(Referral ID)</sm></p>
            <p className="text-center text-sm text-lime-400 mt-4 mb-4">Your Invitee Address has been detected!</p>

            <div className="mb-4">
                <label className="block text-sm font-medium text-lime-400">Invitee Address:</label>
                <input
                type="text"
                    value={inviteeAddress}
                    onChange={(e) => setInviteeAddress(e.target.value)} // Update state on input change
                    readOnly
                    className="w-full p-2 border text-stone-900 border-violet-500 bg-blue-200 rounded-md"
            
                />
           
            </div>
        <div className="grid grid-cols-2 gap-4">
        <button onClick={BecomeAMiner} className="px-4 py-3 mt-5 bg-violet-500 rounded-md hover:bg-lime-600 transition animate-fadeIn delay-400">
          Start Mining
        </button>
                 <button 
          onClick={connectWallet} 
          className="px-2 py-3 mt-5 bg-violet-500 rounded-md hover:bg-lime-600 transition animate-fadeIn delay-400"
      >
          Wallet {status}
       </button>
        </div>

          
        </div>


      
        </div>
        <div className=" m-10  pt-10" >   <RoadMap /> </div>

      </div>
   
    );
  };
  





// src/ReferralPage.js



const ReferralPage = () => {
    // const [account] = useState(''); // Exa/mple referral ID
    // const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const referralLink = `https://torabium.com/referral/${account}`;
    const shareText = `Join me on this platform and get rewards! Here’s my referral link: ${referralLink}`;

    const copyToClipboard = () => {
        navigator.clipboard.writeText(referralLink);
        setMessage('Referral link copied to clipboard!');
        setTimeout(() => setMessage(''), 3000);
    };

    const CopyToClipboard = () => {
      navigator.clipboard.writeText(account);
      setMessage('Referral Address copied to clipboard!');
      setTimeout(() => setMessage(''), 3000);
  };

    const shareOnSocialMedia = (platform) => {
        let url = '';

        switch (platform) {
            case 'twitter':
                url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}`;
                break;
            case 'facebook':
                url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(referralLink)}`;
                break;
            case 'whatsapp':
                url = `https://api.whatsapp.com/send?text=${encodeURIComponent(shareText)}`;
                break;
            case 'telegram':
                url = `https://t.me/share/url?url=${encodeURIComponent(referralLink)}&text=${encodeURIComponent(shareText)}`;
                break;
            case 'email':
                url = `mailto:?subject=Check this out!&body=${encodeURIComponent(shareText)}`;
                break;
            case 'tiktok':
                url = `https://www.tiktok.com/share?url=${encodeURIComponent(referralLink)}`;
                break;
            case 'snapchat':
                url = `https://snapchat.com/share?text=${encodeURIComponent(shareText)}`;
                break;
            case 'instagram':
                url = `https://www.instagram.com/?url=${encodeURIComponent(referralLink)}`;
                break;
            default:
                break;
        }

        if (url) {
            window.open(url, '_blank');
        }
    };

    return (
        <div className="max-w-lg mx-auto p-6 bg-neutral-800 h-screen rounded-lg shadow-md">
            <h1 className="text-2xl font-bold text-center text-violet-500">Invite Your Friends</h1>
            <p className="text-center text-lime-300 mb-4">Share your referral link and earn $5 Plus 5000 $TORA Instantly!</p>

            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-300">Your Referral Link:</label>
                <div className="flex">
                    <input
                        type="text"
                        value={referralLink}
                        readOnly
                        className="flex-1 p-2 border border-violet-500 bg-blue-200  rounded-l-md"
                    />
                    <button
                        onClick={copyToClipboard}
                        className="bg-violet-500 text-gray-300 px-4 rounded-r-md hover:bg-blue-700"
                    >
                        Copy Link
                    </button>
                </div>

                <label className="block text-sm font-medium text-gray-300 mt-5">Your Referral ID (Referral Address):</label>
                <div className="flex">
                    <input
                        type="text"
                        value={account}
                        readOnly
                        className="flex-1 p-2 border border-violet-500 bg-blue-200  rounded-l-md"
                    />
                    <button
                        onClick={CopyToClipboard}
                        className="bg-violet-500 text-gray-300 px-4 rounded-r-md hover:bg-blue-700"
                    >
                        Copy Address
                    </button>
                </div>
                {message && <div className="text-center text-lime-500 mt-2">{message}</div>}

            </div>


            <h2 className="text-lg font-semibold mb-2 text-lime-300">Share on Social Media:</h2>
            <div className="flex grid grid-cols-2 ">
                <button onClick={() => shareOnSocialMedia('twitter')} className="bg-stone-900 text-white px-3 py-2 rounded mr-3">Twitter</button>
                <button onClick={() => shareOnSocialMedia('facebook')} className="bg-blue-600 text-white px-3 py-2 rounded mr-3">Facebook</button>
                <button onClick={() => shareOnSocialMedia('whatsapp')} className="bg-green-500 text-white px-3 py-2 rounded mt-3 mr-3 ">WhatsApp</button>
                <button onClick={() => shareOnSocialMedia('telegram')} className="bg-blue-500 text-white px-3 py-2 rounded mt-3 mr-3">Telegram</button>
                <button onClick={() => shareOnSocialMedia('email')} className="bg-gray-300 text-black px-3 py-2 rounded mt-3 mr-3">Email</button>
                <button onClick={() => shareOnSocialMedia('tiktok')} className="bg-black text-white px-3 py-2 rounded mt-3 mr-3">TikTok</button>
                <button onClick={() => shareOnSocialMedia('snapchat')} className="bg-yellow-400 text-gray-800 px-3 py-2 rounded mt-3 mr-3">Snapchat</button>
                <button onClick={() => shareOnSocialMedia('instagram')} className="bg-gradient-to-r from-pink-500 to-yellow-500 text-white px-3 py-2 rounded mt-3 mr-3">Insta</button>
            </div>

            <h2 className="text-lg font-semibold mt-8 mb-2 text-lime-400">Join Us on Social Media:</h2>
            <div className="flex space-x-4 justify-center rounded-xl pt-4 pb-4 text-violet-200">
            <a href="https://x.com/Toraecosystem" target="_blank" rel="noopener noreferrer">
                    <i ><BsTwitterX className="h-8 w-8"/>
                    </i>
                </a>
                <a href="https://www.facebook.com/profile.php?id=61566781888506&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
                    <i  ><FaFacebook className="h-8 w-8"/>
                    </i>
                </a>
                <a href="https://t.me/ToraMining" target="_blank" rel="noopener noreferrer">
                    <i  ><FaTelegram className="h-8 w-8"/>
                    </i>
                </a>
                <a href="https://www.youtube.com/@Toraecosystem" target="_blank" rel="noopener noreferrer">
                    <i  ><FaYoutube className="h-8 w-8"/>
                    </i>
                </a>
                <a href="https://www.instagram.com/toraecosytem/" target="_blank" rel="noopener noreferrer">
                    <i  ><GrInstagram className="h-8 w-8 "/>
                    </i>
                </a>
            </div>

        </div>
    );
};










 const profData = {
  profileImageSrc: profilepic,
  description: `Mine $TORA Tokens Easily and earn instant Reward of $5 for each new member you invite.

Enjoy the benefits of Instant Withdrawals and real-time access to legitimate balances and data on the blockchain. `,
  aboutItems: [
    {label: 'Circulating Supply', value: Supply , Icon: ChartPieIcon},
    {label: 'Burnt Supply', value: burnt , Icon: FireIcon},
    {label: 'Stage Miners', value: Miners + 313 , Icon: UserGroupIcon},
    {label: 'Mining Stage', value: CStage, Icon: RocketLaunchIcon},
    {label: 'Mining Speed' , value: SpeedD, Icon: BoltIcon},
    {label: 'Mining Session' , value: secondsToDhms(Sec), Icon: ClockIcon},
    {label: 'Your Balance', value: Bal, Icon: CurrencyDollarIcon},
    {label: 'Mined Balance', value: MinedBal, Icon: BiCoinStack},
    {label: 'Friends Invited', value: Rcount, Icon: UsersIcon},
    {label: 'CA', value: contractAddress, Icon: FaFileContract},




  ],
}; 


const Profile = () => {
  const {profileImageSrc, description, aboutItems} = profData;
    return(


      <div className="bg-neutral-800 bg-cover w-full h-full p-5 ">
      <div className='grid grid-cols-1 gap-y-4 md:grid-cols-4 mt-10 mb-20'>
        {!!profileImageSrc && (
          <div className="col-span-1 flex justify-center md:justify-start">
            <div className="relative h-24 w-24 overflow-hidden rounded-xl md:h-32 md:w-32">
              <img alt="about-me-image" className="h-full w-full object-cover" src={profileImageSrc} />
            </div>
          </div>
        )}
        <div className='col-span-1 flex flex-col gap-y-6 md:col-span-3' >
          <div className="flex flex-col gap-y-2">
            <h2 className="text-2xl font-bold text-violet-500">Profile</h2>
            <p className="prose prose-sm text-gray-300 sm:prose-base">{description}</p>
          </div>
          <ul className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            {aboutItems.map(({label, value, Icon}, idx) => (
              <li className="col-span-1 flex  items-start gap-x-2" key={idx}>
                {Icon && <Icon className="h-5 w-5 text-violet-400" />}
                <span className="text-sm font-bold text-violet-400 ">{label}:</span>
                <span className=" text-sm text-gray-300">{value}</span>
              </li>
            ))}
          </ul> 
        </div>
      </div>
    </div>

    );




}

const images = [
  {
    src: Hero1,
    Rank: 1,
    name: 'Rover',
    Level: H1LVL,
    Speed: 221 * H1LVL + 221,
    inviteCount: 2,
    Price: H1LVL*1024,
    description: 'Rover is the adventurous hero who fearlessly explores underground caverns to extract valuable Torabium. With a keen sense of discovery, he uncovers hidden treasures, making every mining expedition an exciting journey into the depths of the crypto world!',
    PrInvite:2.5, 
    PrSpeed:2.5 * H1LVL +2.5,
    PrPrice: 2 * H1LVL,
  },
  {
    src: Hero2,
    Rank: 2,
    name: 'Meteoric',
    Level: H2LVL,
    Speed: 442 *H2LVL + 442,
    inviteCount: 5,
    Price: 2048*H2LVL,
    description: 'Meteroic is a cosmic hero endowed with the ability to harness energy from celestial bodies, using his extraordinary skills to protect the universe. He scrapes torabium from the surfaces of meteors, transforming it into powerful resources for his interstellar missions.',
    PrInvite:5, 
    PrSpeed:5*H2LVL+5,
    PrPrice: 4*H2LVL,
  },
  {
    src: Hero3,
    Rank: 3,   
    name: 'Astro',
    Level: H3LVL,
    Speed: 663,
    inviteCount: 7,
    Price: 3072,
    PrInvite:8.5, 
    PrSpeed:7.5,
    PrPrice: 6,
    description: 'Astro is a fearless cosmic explorer, navigating the depths of the universe in search of precious resources. He drills deep into planets to extract torabium, turning cosmic adventures into lucrative opportunities for the next generation of crypto enthusiasts.',
  },
  {
    src: Hero4,
    Rank: 4,
    name: 'Quantum Scanner',
    Level: H4LVL,
    Speed: 884,
    inviteCount: 10,
    Price: 4096,
    PrInvite:10, 
    PrSpeed:9,
    PrPrice: 8,
    description: 'Quantum Scanner is a high-tech treasure hunter, equipped with cutting-edge technology to uncover hidden riches beneath the surface. She scans underground to find torabium veins, transforming the quest for crypto wealth into an exhilarating race against time and rivals.',
  },
  {
    src: Hero5,
    Rank: 5,
    name: 'Gravimetric',
    Level: H5LVL,
    Speed: 1105,
    inviteCount: 20,
    Price: 5120,
    PrInvite:20, 
    PrSpeed:20,
    PrPrice: 10,
    description: 'Gravimetric is a master of gravitational forces, skillfully navigating the cosmos to reveal the secrets of the universe. She locates torabium in planetary cores using gravity waves, turning the hunt for crypto treasures into a thrilling adventure filled with cosmic wonders.',
  },
  {
    src: Hero6,
    Rank: 6,
    name: 'Aurora',
    Level: H6LVL,
    Speed: 1768,
    inviteCount: 50,
    Price:8192,
    PrInvite:40, 
    PrSpeed:42,
    PrPrice: 25,
    description: 'Aurora is an enchanting guardian of the skies, channeling the ethereal beauty of the northern lights to gather cosmic wealth. She collects torabium from particles in auroras, transforming breathtaking displays into a vibrant source of energy for the crypto mining universe.',
  },
  {
    src: Hero7,
    Rank: 7,
    name: 'Celestial',
    Level: H7LVL,
    Speed: 2652,
    inviteCount: 100,
    Price: 15360,
    PrInvite:60, 
    PrSpeed:50,
    PrPrice: 40,
    description: 'Celestial is a daring space pioneer, wielding advanced plasma cutters to carve through the cosmos in pursuit of hidden treasures. She uses plasma cutters to mine torabium from asteroids, turning the thrill of interstellar exploration into a lucrative venture for crypto enthusiasts everywhere.',
  },
  {
    src: Hero8,
    Rank: 8,
    name: 'Nebula',
    Level: H8LVL,
    Speed: 3757,
    inviteCount: 200,
    Price: 20480,
    PrInvite:70, 
    PrSpeed:60,
    PrPrice: 50,
    description: 'Nebula is a mystical space alchemist, swirling through vibrant gas clouds in search of hidden riches. She filters cosmic dust in gas clouds for torabium, transforming the enchanting chaos of the universe into a treasure trove for the crypto mining frontier.',
  },
  {
    src: Hero9,
    Rank: 9,
    Upgrade: {upgradeQuantumM},
    name: 'Quantum_Miner',
    Level: H9LVL,
    Speed: 5525,
    inviteCount: 300,
    Price: 30720,
    PrInvite:85, 
    PrSpeed:75,
    PrPrice: 70,
    description: 'Quantum Miner is a bold adventurer at the edge of the cosmos, fearlessly navigating treacherous landscapes in pursuit of rare resources. She harvests torabium from unstable volatile planets, turning the risks of cosmic chaos into thrilling opportunities for crypto fortune seekers.',
  },
  {
    src: Hero10,
    Rank: 10,
    name: 'Hydra',
    Level: H10LVL,
    Speed: 8840,
    inviteCount:600,
    Price: 51200,
    PrInvite:100, 
    PrSpeed:100,
    PrPrice: 90,
    
    description: 'Hydra is a relentless cosmic explorer, gliding through the icy realms of space with unmatched determination. She mines torabium from frozen comets, transforming the chilling beauty of the cosmos into a dazzling bounty for the crypto mining revolution.',
  },
];

const ProgressBar = ({ progress }) => {
  return (
      <div className="w-full bg-gray-200 rounded-full h-3 dark:bg-gray-700">
          <div
              className="bg-lime-300 h-3 rounded-full"
              style={{ width: `${progress}%` }}
          ></div>
      </div>
  );
};



const ToraMining = () => {
        
 
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    const [isOpen, setIsOpen] = useState(false);

    const togglePopover = () => {
        setIsOpen((prev) => !prev);
    };
  
  
    return (
      <div className="relative  items-start w-xl h-full sm:max-w-xl overflow-hidden">
        <div className="items-stretch w-full h-full  overflow-hidden">
            <div className=" absolute grid grid-cols-2  w-full mt-5 justify-center  overflow-hidden z-[2]" >
             
             <div className=" flex w-full   " >
              
             </div>
            </div>
            <div className=" absolute grid grid-cols-3  w-screen  justify-center bg-stone-800/60 rounded-xl m-2 overflow-hidden z-[8]" >

            <div className='grid grid-cols gap-0 text-center '>
                <p className="font-bold text-sm text-violet-500  mt-4  ">Daily Speed</p>
                <p className=" font-bold text-sm text-lime-500  pb-4   ">{SpeedD} $TORA</p></div>
                <div className="py-2 px-5  text-center m-4 ">
          <button onClick={_Mine} className="px-4 py-2  bg-violet-500 text-white rounded-md hover:bg-lime-600 transition animate-fadeIn delay-400 z-[11]">
          Claim
        </button>
         </div>

            <div className='grid grid-cols gap-0 text-center '>
                <p className=" font-bold text-sm text-violet-500  mt-4  ">Mined</p>
                <p className=" font-bold text-sm text-lime-500   pb-4  ">{MinedBal} $TORA</p>
               </div>





            </div>
        <video
          className="absolute   z-[1]"
          src={backgroundMine}
          autoPlay
          loop
          muted
        />
   
        
        </div>

        <Slider {...settings} className="relative sm:max-w-xl object-cover z-[3] mb-5 ">
          {images.map((image, index) => (
            <div key={index} className="flex items-center justify-center p-5 mt-20 ">
                {/* <div className="absolute flex grid grid-cols-1 justify-center w-100 text-lime-400 text-xs mt-20 pr-5  p-5 rounded-xl z-[3] bg-blue-400/60"> 
                <p>Speed: {image.Speed}</p>
                <p>Price: LVL * {image.Price}</p>
                <p>Refer: {image.inviteCount}</p>

              </div> */}
              <img src={image.src} alt={`Slide ${index + 1}`} className="relative rounded-lg justify-center h-30% w-50% p-20 pt-10  mt-16 sm:p-50 sm:mt-40" />

              <div className="py-2 px-5 bg-stone-800/60 rounded-2xl  border-3"> 

              
              <div className="">
              <div className="relative inline-block text-left">

              

          <p
              onClick={togglePopover}
              type="button"
              className="text-violet-500   rounded-full text-2xl  text-center  mt-4 ml-2 dark:hover:bg-lime-400 dark:focus:ring-violet-500"
          >
               <FaInfoCircle />
          </p>

          {isOpen && (
              <div
                  role="tooltip"
                  className="absolute z-10 inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-100 dark:text-lime-300 dark:border-gray-600 dark:bg-stone-800"
              >
                  <div className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
                      <h3 className="font-semibold text-gray-900 dark:text-violet-500">{image.name} Description</h3>
                  </div>
                  <div className="px-3 py-2">
                      <p>{image.description}</p>
                  </div>
                  <div className="absolute w-3 h-3 bg-white border border-gray-200 rounded-full" style={{ top: '-6px', left: '20px' }}></div>
              </div>
          )}
      </div>
              <h1 className="text-violet-500 text-center font-bold text-2xl ">{image.name}</h1>
              <h1 className="text-lime-500 text-center  ">Level: <strong className='font-bold'>{image.Level}</strong></h1>

            
        </div>
        <div className="py-2 px-5">
            <h2 className="  text-violet-300 text-sm">Upgrade Cost: {image.Price} $TORA</h2>
            <ProgressBar progress={image.PrPrice} />
        </div>
        <div className="py-2 px-5">
            <h2 className="  text-violet-300 text-sm">Speed: +{image.Speed} TORA/DAY</h2>
            <ProgressBar progress={image.PrSpeed} />
        </div>
        <div className="py-2 px-5">
            <h2 className="  text-violet-300 text-sm">Requirement: {image.inviteCount} Referrals</h2>
            <ProgressBar progress={image.PrInvite} />
        </div>   


         <div className="py-2 px-5  text-center m-4 ">
        <button onClick={HeroUpgrade[image.Rank]} className="px-4 py-2 text-white text-sm hover:scale-110% bg-violet-500 rounded-md hover:bg-lime-500 transition animate-fadeIn delay-400 ">
          Upgrade {image.name}
        </button>
         </div>

       
              </div>

            </div>
          ))}
        </Slider>

        <div className="   w-full justify-center overflow-hidden z-[1]" >
             
             <div className=" flex w-full   " ><img className='h-full w-full mt-5 opacity-0 ' alt='' src={lefts}/></div>
        

            </div>

      </div>
    );
  };








const HomePage = () => {
  // Replace with a random GIF URL or use a GIF API

  return (
    <div className="relative bg-gray-950 w-full h-full overflow-hidden">
    <video
          className="absolute  justify-start  h-screen w-screen overflow"
          src={backgroundHome}
          autoPlay
          loop
          muted
        />
      <div className="absolute  inset-0 bg-black opacity-50"></div>
      <div className="relative flex flex-col justify-center backdrop-blur-sm bg-zinc-500/30 items-center rounded-2xl text-white text-center p-10 m-10 mt-40">
        <h3 className="text-3xl font-bold mb-4 text-violet-500 transform transition duration-500 hover:scale-105 animate-fadeIn">
          ToraDeFi
        </h3>
        <>
      <p className="prose-sm text-lime-200 text-start sm:prose-base lg:prose-lg">
      <strong className="text-lime-300"> Mine TORA DeFi </strong> and earn an instant 
        <strong className="text-lime-300"> Reward of $5 </strong> 
        for each new member you invite.
      </p>
      <p className="prose-sm text-lime-200 text-start sm:prose-base lg:prose-lg">
      Enjoy the benefits of  <strong className="text-lime-300"> Instant Withdrawals </strong>
      with real-time Data on the blockchain.</p>
      <p> <br />
        <strong className="text-violet-500 "> Join us in building a thriving community where everyone can succeed!</strong>
      </p>
    </>

    <div className="max-w-lg mx-auto p-3 bg-violet-600/30 rounded-lg shadow-md mt-5">
            <p className="text-xl font-bold text-violet-100 text-center">Invitee Address</p>
            <p className="text-center text-sm text-lime-400 mt-2 mb-2">Paste Your Invitee Address & Start Mining!</p>

            <div className="mb-2">
                <input
                type="text"
                    value={inviteeAddress}
                    onChange={(e) => setInviteeAddress(e.target.value)} // Update state on input change
                    placeholder="Enter Invitee Address"
                    className="w-full p-2 border text-stone-900 border-violet-500 bg-blue-200 rounded-md"
                    required
                />
            </div>
            </div>
        <div className="grid grid-cols-2 gap-4">
        <button onClick={BecomeAMiner} className="px-4 py-3 mt-5 bg-violet-500 rounded-md hover:bg-lime-600 transition animate-fadeIn delay-400">
          Start Mining
        </button>
                 <button 
          onClick={connectWallet} 
          className="px-2 py-3 mt-5 bg-violet-500 rounded-md hover:bg-lime-600 transition animate-fadeIn delay-400"
      >
          Wallet {status}
       </button>
        </div>

      </div>
      <div className=" m-10 mt-40 pt-10" >   <RoadMap /> </div>
    </div>
 
  );
};






const Mining = () => {
  return (
    <div className="bg-zinc-950 bg-opacity-95 min-h-screen min-w-screen flex  justify-center">
      {/* <h1 className="text-center text-3xl font-bold text-violet-500 hover:text-lime-500 ">Image Carousel</h1> */}
      <ToraMining />
    </div>
  );
};


  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const url = "https://torabium.com";

  useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth <= 768);
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (

    <div>
    {isMobile ? (
 <Router  className="">
 <div className="flex flex-col max-w-lg h-screen ">
   <div className=" flex-1    ">
     <Routes>
       <Route path="/" element={<HomePage />} />
       <Route path="/Mining" element={<Mining />} />
       <Route path="/Mining/" element={<Mining />} />
       <Route path="/profile" element={<Profile />} />
       <Route path="/profile/" element={<Profile />} />
       <Route path="/guide" element={<Guide />} />
       <Route path="/guide/" element={<Guide />} />
       <Route path="/refer" element={<ReferralPage />} />
       <Route path="/refer/" element={<ReferralPage />} />
       <Route path="/referral/:id" element={<Referral/>} />
       <Route path="/referral/:id/" element={<Referral />} />
       <Route path="/" element={<Referral />} />

     </Routes>
   </div>
   <nav className="bg-zinc-900 p-4 max-w-xl sticky bottom-0 z-[9]">
     <div className="flex justify-around">
       <Link onClick={refresh} className="text-violet-500 hover:text-lime-500 justify-center text-center" to="/">
         <div><FaHome className='ml-3'/></div>
         <span><p className='text-sm'>Home</p></span>
       </Link>
       <Link onClick={refresh} className="text-violet-500 hover:text-lime-500 text-center" to="/Mining">
         <div><FaUserAstronaut className='ml-3'/></div>
         <span><p className='text-sm'>Heroes</p></span>
       </Link>
       <Link onClick={refresh} className="text-violet-500 hover:text-lime-500 text-center" to="/profile">
         <div><FaUserCircle className='ml-3' /></div>
         <span><p className='text-sm'>Profile</p></span>
       </Link>
       <Link onClick={refresh} className="text-violet-500 hover:text-lime-500 text-center" to="/guide">
         <div><GiWhiteBook className='ml-2' /></div>
         <span><p className='text-sm'>Guide</p></span>
       </Link>
       <Link onClick={refresh} className="text-violet-500 hover:text-lime-500 text-center" to="/refer">
         <div><FaUserPlus className='ml-3' /></div>
         <span><p className='text-sm'>Invite</p></span>
       </Link>
     </div>
   </nav>
 </div>
</Router>    ) : (
           <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
           <h1 className="text-2xl font-bold mb-4">Welcome to Tora Mining!</h1>
           <p className="text-center mb-6">
               Scan the QR code below with your phone to start Mining from your phone.
           </p>
           <QRCodeSVG value={url} size={256} />
           <p className="mt-4 text-sm text-gray-600">
               Use your phone's camera or a QR code scanner app to open TORA Mining App.
           </p>
       </div>
    )}
</div>
   
  );
};

export default App;

const RoadMap = () =>{ 

    return (

<div>
<p className='justify-self-center m-20 text-lime-300 mt-20 mr-7 pr-10 text-3xl'><strong>Road Map</strong></p>

<ol class="relative border-s border-gray-200 dark:border-gray-700">                  
<li class="mb-10 ms-4">
  <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
  <time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">October 2024</time>
  <h3 class="text-lg font-semibold text-gray-900 dark:text-violet-500">Launch Torabium Ecosystem</h3>
  <p class="mb-4 text-base font-normal text-gray-500 dark:text-lime-200">⦿ Develop Beta Mining App</p>
  <p class="mb-4 text-base font-normal text-gray-500 dark:text-lime-200">⦿ Develop Secure and Advanced Smart Contract</p>
  <p class="mb-4 text-base font-normal text-gray-500 dark:text-lime-200">⦿ Creating Community</p>
  <p class="mb-4 text-base font-normal text-gray-500 dark:text-lime-200">⦿ Starting Mining Stage 1</p>
  <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">⦿ Building Partners</p>
  <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">⦿ Marketing Phase 1</p>
  <a href="https://torabium.com/TORA_Whitepaper.pdf" target="blank" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-100 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-700">Check WhitePaper <svg class="w-3 h-3 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
</svg></a>
</li>
<li class="mb-10 ms-4">
  <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
  <time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">January 2025</time>
  <h3 class="text-lg font-semibold text-gray-900 dark:text-violet-500">Listing & Halving</h3>
  <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">⦿ Updating Mining App</p>
  <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">⦿ Phase 2 Marketing Push</p>
  <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">⦿ Hosting Competitive Events</p>
  <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">⦿ Adding Usecases to TORA Tokens</p>
  <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">⦿ Pursue Listing On Binance</p>
  <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">⦿ Pursue Listing On other CEXs</p>
  <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">⦿ Launching ToraSwap</p>
  <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">⦿ Expanding Partnerships</p>
</li>
<li class="ms-4">
  <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
  <time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">June 2025</time>
  <h3 class="text-lg font-semibold text-gray-900 dark:text-violet-500">Further Development of Ecosystem</h3>
  <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">⦿ Launching On Solana Blockchain</p>
  <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">⦿ Upgrading ToraSwap To add more Pairs</p>
  <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">⦿ Listing On More Exchanges</p>
  <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">⦿ Creating More Usecases for TORA </p>
  <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">⦿ Expanding Marketing approaches</p>
  <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">⦿ Initializing TORA Blockchain Development</p>
  <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">⦿ Blockchain Ecosystem Development</p>
</li>

<li class="ms-4">
  <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
  <time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">September 2025</time>
  <h3 class="text-lg font-semibold text-gray-900 dark:text-violet-500">Marketing and Further Development of TORA Blockchain</h3>
  <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">⦿ Pursue Marketing Partnerships</p>
  <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">⦿ Creating a Super Transparent Mining System for TORA Miners on TORA Blockchain</p>
  <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">⦿ Mining Compute and Validation System </p>
  <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">⦿ Transparent, Fast and Ultra Cheap Transactions</p>
  <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">⦿ Blockchain Ecosystem Development</p>
  <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">⦿ Simplifying Ecosystem for developers</p>
  <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">⦿ Transfering TORA Tokens from Other Blockchains to TORA Blockchain</p>

</li>
</ol>




</div>
      

    )




    
}


export default RoadMap;